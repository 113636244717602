<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <!-- {{ $route.query }} -->
        <c-table 
          :url="'PurchaseWebService/get_merchant_category_list'" 
          :per-page=10 
          :fields="columns" 
          :columns="['icon', 'code', 'name', 'isActive']"
          :filename="'Merchant Category List'"
          orderDirection="ASC"
          orderBy="id"
          hover bordered  fixed caption="" 
          :edit_has="true"
          :edit_route="'admin.purchase.merchant.category.edit'"
          :edit_params="{id:0}"
        >
        </c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Customers.list',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        { label: 'Parent', key: 'parentName'},
        { label: 'Icon', key: 'icon', class: 'text-center'},
        { label: 'Код', key: 'code', sortable: true, sort_key:'code'},
        { label: 'Нэр', key: 'name', sortable: true, sort_key:'name'},
        { label: 'Идэвхтэй эсэх', key: 'isActive', class: 'text-center'},
        { label: 'Үйлдэл', key: 'actions', class:"text-center"}
      ]
    }
  },
  created: function() {
    
  }
}
</script>
